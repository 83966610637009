<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div >
        <div >
          <label class="txt-right">Tên hạng mục chi phí <span class="span-cus">*</span></label>
          <!-- <div class="p-col"> -->
          <Textarea
            v-model="name"
            :autoResize="true"
            rows="1"
            maxlength="255"
            class="text-box input-text-box"
            placeholder="Nhập"
          />
          <input class="input_tmp_validator" id="name" />
          <!-- </div> -->
        </div>
        <div >
          <label class="txt-right mt-3">Mã dịch vụ <span class="span-cus">*</span></label>
          <Textarea
            v-model="code"
            :autoResize="true"
            rows="1"
            maxlength="50"
            class="text-box input-text-box"
            placeholder="Nhập"
          />
          <input class="input_tmp_validator" id="code" />
        </div>
        <div>
          <label class="txt-right mt-3">Đơn vị tính <span class="span-cus">*</span></label>
          <!-- <v-select :clearable="false" v-model="selUnit" :options="listUnit" @search="fetchOptionsUnit" :get-option-label="(option) => option.name" class="text-box no-arrow" placeholder="Nhập">
            <template #no-options="{ search }">
              <a class="text-primary-new pointer" @click="addUnit(search)">Thêm mới</a>
            </template>
          </v-select> -->
          <InputText type="text" v-model="unit_name" placeholder="Nhập" maxlength="50" class="text-box input-text-box"/>
          <input class="input_tmp_validator" id="unit_id">
        </div>
        
        <div>
          <label class="txt-right mt-3">Nhóm báo cáo <span class="span-cus">*</span></label>
          <v-select :clearable="false" v-model="selSummaryReport" :options="listSummaryReport" @search="fetchOptionsSummaryReport" :get-option-label="(option) => option.name" class="text-box" placeholder="Chọn" />
          <input class="input_tmp_validator" id="summary_report_id">
        </div>
        <div>
          <label class="txt-right mt-3">Số thứ tự </label>
          <InputNumber
              v-model="ordinal"
              id="ordinal"
              class="text-box d-block"
              placeholder="Nhập"
              mode="decimal"
              :minFractionDigits="0"
          />
        </div>
        
        <ShippingMethodPrice :data="shipping_method_prices" :mode="mode" />
        <input class="input_tmp_validator" id="shipping_method_price_id">
        <div class="flex justify-content-center mt-3">
          <Checkbox v-model="active" :binary="true" /> Hiển thị
        </div>
      </div>

      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
            :label="$constants.TEXT_BUTTON.BACK"
            @click="backToList()"
            class="p-button-warning callback-btn"
          />
          <Button
            v-if="mode == 'edit'"
            :label="$constants.TEXT_BUTTON.EDIT"
            @click="saveData()"
            class="main-style-button"
          />
          <Button
            v-else
            :label="$constants.TEXT_BUTTON.ADD"
            @click="saveData()"
            class="main-style-button"
          />
        </template>
      </Toolbar>
      <Dialog
          :key="keyPopup"
          :visible.sync="showDuplicatedDialog"
          :contentStyle="{ overflow: 'visible' }"
          :modal="true"
        >
        Đã tồn tại đơn giá dịch vụ logistics trước đó, vui lòng kiểm tra lại!
          <template #footer>
            <Button
              label="Xác nhận"
              @click="showDuplicatedDialog = false"
              icon="pi pi-check"
              class="p-button-success main-style-button"
            />
          </template>
        </Dialog>
    </div>
  </form>
</template>

<script>
import DataServices from "@/core/DataServices";
import ShippingMethodPrice from "../ShippingMethodPrice";
// import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array,
  },
  components: {
    ShippingMethodPrice,
  },
  data() {
    return {
      shipping_method_prices: [],
      code: "",
      name: "",
      unit_id: null,
      unit_name: "",
      selUnit: null,
      selSummaryReport: null,
      ordinal: null,
      active: true,
      formMode: this.mode,
      list_group_role: [],
      saving: false,
      listUnit: [],
      listUnitAll: [],
      listSummaryReport: [],
      listSummaryReportAll: [],
      showDuplicatedDialog: false,
    };
  },
  async mounted() {
    await this.getListUnit();
    await this.getlistSummaryReport();
  },
  async created() {
    if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
      await this.getData(this.$route.params.id);
    }
  },
  methods: {

    async addUnit(name) {
      if (!this.$commonFuction.isEmpty(name)) {
        await this.$apollo.mutate({
              mutation: DataServices.insertData('unit'),
              variables: {
                objects: {"name": name}
              }
          }).then(async (res) => {
              if (res.data[Object.keys(res.data)].affected_rows) {
                var returning = res.data[Object.keys(res.data)].returning[0];
                this.selUnit = {"id": returning.id, "name": name}
                console.log("just added unit", this.selUnit);
                // await this.getListUnit();
              }
          }).catch((e)=> {
            console.log(e);
          })
      }
    },
    async getListUnit(){
      var where_key = {
        deleted : {_eq : false}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('unit',{"fields" : `id name`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_unit = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_unit.push({id : e.id, name :e.name});
      }
      this.listUnit = list_unit;
      this.listUnitAll = list_unit;
    },
    async fetchOptionsUnit(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('unit',{"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_unit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_unit.push({id : e.id, name :e.name});
        }
        this.listUnit = list_unit;
      }else{
        // cosnole.log("dasdasd")
        this.listUnit = this.listUnitAll
      }
      return this.listUnit;
    },
    
    async getlistSummaryReport(){
      var where_key = {
        deleted : {_eq : false},
        _or: [
          {parent_id : {_is_null : false}},
          {symbol: {_eq: "III"}}
        ]
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('summary_report_operate_service',{"fields" : `id name`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_sr = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_sr.push({id : e.id, name :e.name});
      }
      this.listSummaryReport = list_sr;
      this.listSummaryReportAll = list_sr;
    },
    async fetchOptionsSummaryReport(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          _or: [
            {parent_id : {_is_null : false}},
            {symbol: {_eq: "III"}}
          ],
          name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('summary_report_operate_service',{"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_sr = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_sr.push({id : e.id, name :e.name});
        }
        this.listSummaryReport = list_sr;
      }else{
        // cosnole.log("dasdasd")
        this.listSummaryReport = this.listSummaryReportAll
      }
      return this.listSummaryReport;
    },
    backToList() {
      this.$emit("back");
    },
    async checkCode(code) {
      var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          type: {_eq: this.$constants.SHIPPING_METHOD_OTHER},
          code:{_ilike: "%"+this.$commonFuction.SkipVN(code).trim()+"%"},
          id: {_neq: this.$route.params.id},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('shipping_method',{"fields" : `id name code`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_sr = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_sr.push({id : e.id, name :e.name});
        }
        if (list_sr.length > 0) {
            return true;
        }
        return false;
    },
    
    async validateData() {
      this.validationErrors = {};

      let name_add = document.getElementById("name");
      if (
        this.name == undefined ||
        this.name == null ||
        this.name == ""
      ) {
        name_add.setCustomValidity("Tên hạng mục không được để trống!");
      } else {
        name_add.setCustomValidity("");
      }
      
      let code_add = document.getElementById("code");
      if (
        this.code == undefined ||
        this.code == null ||
        this.code == ""
      ) {
        code_add.setCustomValidity("Mã dịch vụ không được để trống!");
      } else {
        var check = await this.checkCode(this.code);
        if (check) {
          code_add.setCustomValidity(`Mã dịch vụ ${this.code} đã tồn tại!`);
        } else {
          code_add.setCustomValidity("");
        }
      }
      
      let unit_add = document.getElementById("unit_id");
      if (
        this.$commonFuction.isEmptyObject(this.unit_name)
      ) {
        unit_add.setCustomValidity("Đơn vị tính không được để trống!");
      } else {
        unit_add.setCustomValidity("");
      }
      
      let summary_report_add = document.getElementById("summary_report_id");
      if (
        this.$commonFuction.isEmptyObject(this.selSummaryReport)
      ) {
        summary_report_add.setCustomValidity("Nhóm báo cáo không được để trống!");
      } else {
        summary_report_add.setCustomValidity("");
      }
      
      let shipping_method_price_add = document.getElementById("shipping_method_price_id");
      //reassign shipping_method_prices by eliminating element with empty selCompany and price
      this.shipping_method_prices = this.shipping_method_prices.filter( m => { return (!this.$commonFuction.isEmptyObject(m.selCompany) || !this.$commonFuction.isEmpty(m.price))});
      if (
        this.shipping_method_prices.length < 1
      ) {
        shipping_method_price_add.setCustomValidity("Công ty hoặc đơn giá không được để trống!");
      } else {
        var check_price  = this.shipping_method_prices.filter( m => { return (!this.$commonFuction.isEmptyObject(m.selCompany) || !this.$commonFuction.isEmpty(m.selCompany)) && !this.$commonFuction.isEmpty(m.price) })
        // check_price stores the list of shipping_method_prices that has only one field filled in
        
        if (check_price.length != this.shipping_method_prices.length) {
          shipping_method_price_add.setCustomValidity("Công ty hoặc đơn giá không được để trống!");
        } else {
          shipping_method_price_add.setCustomValidity("");
        }
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()) {
        console.log("test=====================");
        inpObj.reportValidity();
        return true;
      }
    },
    async saveData() {
      // console.log(this.shipping_method_prices);
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        // this.saving = false;
        this.$commonFuction.is_loading(false);
        return;
      }
      // find the corresponding unit_id from unit_name in listUnit
      if (!this.$commonFuction.isEmpty(this.unit_name)) {
        var unit = this.listUnit.find( m => { return m.name == this.unit_name});
        console.log("Found unit", unit);
        if (!this.$commonFuction.isEmptyObject(unit)) {
          this.selUnit = unit;
        } else {
          await this.addUnit(this.unit_name);
          console.log("added unit", this.selUnit);
        }
      }
      if (Object.entries(this.validationErrors).length !== 0)
        this.$commonFuction.is_loading(false);
      console.log("newly added unit", this.selUnit);
      // if (!this.account_exist) {
      var params = {
        shipping_method_prices: this.shipping_method_prices.map(e => {return {company_id: e.selCompany?.id, price: e.price}}),
        code: this.code,
        name: this.name,
        unit_id: this.selUnit?.id || null,
        summary_report_id: this.selSummaryReport?.id || null,
        ordinal: this.ordinal,
        active: this.active,
      };
      this.$emit("save", params);
      // }
    },
    async getData(id) {
      var { data } = await this.$apollo.query({
        query: DataServices.getList("shipping_method", { "field": "id name code ordinal shipping_method_prices{id price company{id code}} summary_report{id name} unit{id name}"}),
        variables: {
          where_key: {
            id: { _eq: id },
            type: {_eq: this.$constants.SHIPPING_METHOD_OTHER}
          },
        },
        fetchPolicy: "network-only",
      });
      var model = data[Object.keys(data)];
      console.log("THis is the model", model);
      if (model.length > 0) {
        this.models = model[0];
        this.selUnit = {id: this.models.unit?.id, name: this.models.unit?.name} || null;
        this.unit_name = this.models.unit?.name || null;
        this.selSummaryReport = {id: this.models.summary_report?.id, name: this.models.summary_report?.name} || null;
        this.shipping_method_prices = this.models?.shipping_method_prices.map(m => { return {selCompany: {id: m.company?.id, code: m.company?.code}, price: m.price}}) || [];
        this.name = this.models.name;
        this.code = this.models.code;
        this.active = this.models.active;
        this.ordinal = this.models.ordinal;
      }
      this.componentKey++;
    }
    
  },
  /*apollo: {
        category: {
          query() {
            return DataServices.getList('category', {"fields" : "id name"});
          },
          update(data) {
            if(data.category){
              this.categories = data.category
            }
          },
          fetchPolicy: "network-only"
        },
      }*/
};
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.category-item-value {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container {
  height: 40px;
}

label {
  font-weight: bold;
}

.radio-option {
  margin-bottom: 0 !important;
}
.required:after {
  content: " *";
  color: red;
}
.no-arrow{
  .vs__actions{
    display: none !important;
  }
}
</style>